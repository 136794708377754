import Utilities from "./Utilities";

export default class Configuration {

  constructor(configObject) {

    window.CookieConsent.buffer = {
      appendChild: [],
      insertBefore: []
    };

    // Wrapper filter function
    window.CookieConsent.wrapper = function () { };

    // Settings injector for users
    window.CookieConsent.setConfiguration = this.setConfiguration.bind(this);

    window.CookieConsent.config = {
      active: true,
      cookieExists: false,
      cookieVersion: 1,
      modalMainTextMoreLink: 1,
      barTimeout: 500,
      hasIcon: false,
      saveOnClose: false,
      apiUri: null,
      theme: {
        barColor: '#FFF',
        barTextColor: '#FFF',
        barMainButtonColor: '#102D44',
        barMainButtonTextColor: '#FFF',
        barMainButtonHoverColor: '#1c3b55',
        barMainButtonHoverTextColor: '#FFF',
        modalMainButtonColor: '#102D44',
        modalMainButtonTextColor: '#FFF',
        modalMainButtonHoverColor: '#1c3b55',
        modalMainButtonHoverTextColor: '#FFF',
      },
      language: {
        current: 'pt_BR',
        locale: {
          en: {
            barMainText: "Usamos cookies e tecnologias semelhantes para ajudar a personalizar conteúdos, adaptar e avaliar anúncios e oferecer uma experiência melhor. Ao clicar em OK ou atilet uma opção em Preferências de cookies, você concorda com isso, conforme descrito em nossa <a href='/files/politica-de-privacidade-e-protecao-de-dados.pdf' target='_blank'>Política de Cookies</a>. Para alterar as preferências ou retirar o consentimento, atualize suas Preferências de cookies.",
            barLinkSetting: 'Preferências de cookies',
            barBtnAcceptAll: 'OK',
            barBtnClose: 'Fechar',
            modalMainTitle: 'Sua privacidade',
            modalMainText: 'Quando você visita qualquer site, ele pode armazenar ou recuperar informações em seu navegador, principalmente na forma de cookies. Essas informações podem ser sobre você, suas preferências ou seu dispositivo, e são usadas principalmente para fazer o site funcionar como você espera. As informações geralmente não identificam você diretamente, mas podem lhe dar uma experiência web mais personalizada. Como respeitamos seu direito à privacidade, você pode optar por não permitir alguns tipos de cookies. Clique nos diferentes títulos de categoria para obter mais informações e alterar nossas configurações padrão. No entanto, bloquear alguns tipos de cookies pode afetar sua experiência no site e os serviços que podemos oferecer.',     modalBtnSave: 'Salvar',
            modalBtnAcceptAll: 'Salvar',
            modalBtnACancel: 'Cancelar',
          },
          pt_BR: {
            barMainText: "Usamos cookies e tecnologias semelhantes para ajudar a personalizar conteúdos, adaptar e avaliar anúncios e oferecer uma experiência melhor. Ao clicar em OK ou atilet uma opção em Preferências de cookies, você concorda com isso, conforme descrito em nossa <a href='/files/politica-de-privacidade-e-protecao-de-dados.pdf' target='_blank'>Política de Cookies</a>. Para alterar as preferências ou retirar o consentimento, atualize suas Preferências de cookies.",
            barLinkSetting: 'Preferências de cookies',
            barBtnAcceptAll: 'OK',
            barBtnClose: 'Fechar',
            modalMainTitle: 'Sua privacidade',
            modalMainText: 'Quando você visita qualquer site, ele pode armazenar ou recuperar informações em seu navegador, principalmente na forma de cookies. Essas informações podem ser sobre você, suas preferências ou seu dispositivo, e são usadas principalmente para fazer o site funcionar como você espera. As informações geralmente não identificam você diretamente, mas podem lhe dar uma experiência web mais personalizada. Como respeitamos seu direito à privacidade, você pode optar por não permitir alguns tipos de cookies. Clique nos diferentes títulos de categoria para obter mais informações e alterar nossas configurações padrão. No entanto, bloquear alguns tipos de cookies pode afetar sua experiência no site e os serviços que podemos oferecer.',     modalBtnSave: 'Salvar',
            modalBtnAcceptAll: 'Salvar',
            modalBtnACancel: 'Cancelar',
          },
        }
      },
      categories: {
        required: {
          needed: true,
          language: {
            locale: {
              pt_BR: {
                name: 'Cookies estritamente necessários',
                description: 'Esses cookies são necessários para que o site funcione e não podem ser desativados em nossos sistemas. Eles geralmente são definidos apenas em resposta às suas ações que resultem em uma solicitação de serviços, como definir suas preferências de privacidade, fazer login ou preencher formulários. Você pode configurar seu navegador para bloqueá-los ou alertar você sobre esses cookies, mas algumas partes do site podem não funcionar em decorrência disso.',
              }
            }
          }
        },
        performance: {
          language: {
            locale: {
              pt_BR: {
                name: 'Cookies de desempenho',
                description: 'Esses cookies nos permitem contar visitas e fontes de tráfego, para que possamos medir e aprimorar o desempenho do nosso site. Eles nos ajudam a saber quais páginas são as mais e as menos populares, além de indicar como os visitantes trafegam no site. Todas as informações que esses cookies recolhem são agregadas e, portanto, anônimas. Se você não permitir esses cookies, nós não saberemos quando você visitou nosso site.',
              }
            }
          }
        },
        features: {
          language: {
            locale: {
              pt_BR: {
                name: 'Cookies funcionais',
                description: 'Esses cookies permitem o fornecimento de recursos aprimorados de funcionalidade e personalização, como vídeos e bate-papos ao vivo. Eles podem ser definidos por nós ou por fornecedores terceirizados cujos serviços nós adicionamos às nossas páginas. Se você não permitir esses cookies, algumas ou todas essas funcionalidades podem não funcionar corretamente.',
              }
            }
          }
        }
      },
      services: {
        auth: {
          category: 'required',
          needed: true,
          language: {
            locale: {
              pt_BR: {name: 'Segurança',}
            }
          }
        },
        userxp: {
          category: 'required',
          needed: true,
          language: {
            locale: {
              pt_BR: {name: 'Experiência de usuário'}
            }
          }
        },
        language: {
          category: 'required',
          needed: true,
          language: {
            locale: {
              pt_BR: {name: 'Configurações de idioma',}
            }
          }
        },
        analytics: {
          category: 'features',
          type: 'dynamic-script',
          search: 'analytics',
          cookies: [
            {name: '_gid', domain: `${window.location.hostname}`},
            {name: /^_ga/, domain: `${window.location.hostname}`},
            {name: /^__utm[a-z]/, domain: `${window.location.hostname}`}
          ],
          language: {
            locale: {
              pt_BR: {name: 'Google Analytics'},
              en_US: {name: 'Google Analytics'},
              es_ES: {name: 'Google Analytics'},
            }
          }
        },
        /*
        zendesk: {
          category: 'features',
          type: 'script-tag',
          search: 'zendesk',
          language: {
            locale: {
              pt_BR: {name: 'Zendesk'},
              en_US: {name: 'Zendesk'},
              es_ES: {name: 'Zendesk'},
            }
          }
        }
        */
      }
    };
    this.setConfiguration(configObject);
  }

  setConfiguration(configObject) {
    // The user overrides the default config
    // console.log(window.CookieConsent.config, configObject, { ...window.CookieConsent.config, ...configObject });
    this.mergeDeep(window.CookieConsent.config, configObject);
    // loMerge(window.CookieConsent.config, configObject);
    // The cookie overrides the default and user config
    this.cookieToConfig();
    // We tell the world we did this
    Utilities.dispatchEvent(document, 'CCConfigSet');
  }

  cookieToConfig() {

    function removeReload() {
      Utilities.removeCookie();
      location.reload();
      return false;
    }

    document.cookie.split(';').filter((item) => {
      if (item.indexOf('__cconsent') >= 0) {
        let cookieData = JSON.parse(item.split('=')[1]);
        // console.log(cookieData)
        // We check cookie version. If older we need to renew cookie.
        if (typeof cookieData.version === 'undefined') {
          return removeReload();
        } else {
          if (cookieData.version !== window.CookieConsent.config.cookieVersion) {
            return removeReload();
          }
        }
        // REMOVER? We check if cookie data categories also exist in user config
        if (cookieData.hasOwnProperty('categories')) {
          for (let key in cookieData.categories) {
            // The cookie contains category not present in user config so we invalidate cookie
            if (typeof window.CookieConsent.config.categories[key] === 'undefined') {
              return removeReload();
            }
          }
        }
        // We check if cookie data services also exist in user config
        if (cookieData.hasOwnProperty('services')) {
          cookieData.services.forEach(function (service) {
            let { name, wanted } = service;
            // The cookie contains service not present in user config so we invalidate cookie
            if (typeof window.CookieConsent.config.services[service.name] === 'undefined') {
              return removeReload();
            } else {
              window.CookieConsent.config.services[name].checked =
                  window.CookieConsent.config.services[name].wanted = wanted
            }
          });
        }
        window.CookieConsent.config.cookieExists = true;
        return true;
      }
    });

    return false;
  }

  // Simple object check.
  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  // Deep merge two objects.
  mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return this.mergeDeep(target, ...sources);
  }
}
