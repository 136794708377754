export default class Language {

  setLocale(locale) {
    window.CookieConsent.config.language.current = locale;
  }
  
  static getTranslation(object, locale, key) {
    var currentLocale;
    if(!object.hasOwnProperty('language')) return '[Missing language object]';
    if(!object.language.hasOwnProperty('locale')) return '[Missing locale object]'
    currentLocale = (object.language.locale.hasOwnProperty(locale)) ? locale : 'en';
    var string = (object.language.locale[currentLocale].hasOwnProperty(key)) ? object.language.locale[currentLocale][key] : '[Missing translation]';
    // convert string x elements 
    const domParser = new DOMParser()
    const keyName = key.split(/(?=[A-Z])/).join('-').toLowerCase();
    const className = `ccb_${keyName}`
    string = `<span class="${className}">${string}</span>`;
    const doc = domParser.parseFromString(string, 'text/html')
    return doc.getElementsByClassName(className);
  }

}