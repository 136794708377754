import Utilities from "./Utilities";

export default class Filter  {

  createBlacklist(type) {
    let services = {};
    for (let service in window.CookieConsent.config.services) {
      if (window.CookieConsent.config.services[service].type === type) {
        if (window.CookieConsent.config.services[service].wanted === false) {
          if (window.CookieConsent.config.services[service].wanted === false) {
            services[service] = window.CookieConsent.config.services[service];
          }
        }
      }
    }
    let blacklist = [];
    for (let service in services) {
      let type = Utilities.objectType(services[service].search);
      if (type === 'String') {
        blacklist.push(services[service].search);
      } else if(type === 'Array') {
        for (let i = 0; i < services[service].search.length; i++) {
          blacklist.push(services[service].search[i]);
        }
      }
    }
    return blacklist;
  }

}
