import { el, mount } from 'redom';
import Language from './Language';
import Utilities from "./Utilities";
import Services from "./Services";

export default class Interface {

  constructor() {
    this.elements = {};
  }

  buildStyle() {
    return el('style',
      '#cconsent-icon {background-color:' + window.CookieConsent.config.theme.barColor + ' !important; background-color: ' + window.CookieConsent.config.theme.barMainButtonColor + ' !important; color:' + window.CookieConsent.config.theme.barTextColor + ' !important; position: fixed !important; bottom: 20px !important; left: 20px !important; width: 100px !important; height: 60px !important; z-index: 999997 !important;}',
      '#cconsent-icon a {display: inline-block !important; width: 100px !important; height: 60px !important; cursor:pointer !important;} ',

      '#cconsent-bar, #cconsent-icon, #cconsent-bar * {box-sizing:border-box;}',
      '#cconsent-bar {width: 100% !important; margin: 0 auto; left: 0; right: 0; font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important; font-size: 14px !important; color: #484848 !important; line-height: 1.43 !important; -webkit-font-smoothing: antialiased; max-width: var(--page-shell-max-content-width, 1760px) !important; position: fixed !important; bottom: 0px !important; z-index: 999998 !important; max-width: 1760px !important; padding-left: 24px !important; padding-right: 24px !important;}',
        '@media (min-width: 375px) { #cconsent-bar {padding-left: 24px !important; padding-right: 24px !important} }',
        '@media (min-width: 744px) { #cconsent-bar {padding-left: 40px !important; padding-right: 40px !important} }',
        '@media (min-width: 1128px) { #cconsent-bar {padding-left: 80px !important; padding-right: 80px !important} }',

      '#cconsent-bar .ccb__wrapper {-webkit-box-direction: normal !important; -webkit-box-orient: vertical !important; padding: 16px !important; overflow: hidden !important; margin-bottom: 21px !important; background-color: ' + window.CookieConsent.config.theme.barColor + ' !important; box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px !important; border-radius: 8px !important; display: flex !important; flex-direction: column !important;}',
        '@media (min-width: 744px) { #cconsent-bar .ccb__wrapper {-webkit-box-align: center !important; -webkit-box-direction: normal !important; -webkit-box-orient: horizontal !important; flex-flow: row wrap !important; align-items: center !important; margin-bottom: 28px !important;} }',

      '#cconsent-bar .ccb__left {margin: 8px !important; flex: 1 1 0% !important; min-width: 65% !important;}',
        '@media (min-width: 1128px) { #cconsent-bar .ccb__left {margin-right: 16px !important;} }',

      '#cconsent-bar .ccb__left .cc-text {-webkit-box-align: center !important; margin-bottom: 8px !important; display: flex !important; align-items: center !important;}',
      '@media (min-width: 1128px) { #cconsent-bar .ccb__left .cc-text {margin-right: 16px !important; } } ',

      '#cconsent-bar .ccb__left h1 {font-size: 16px !important; line-height: 20px !important; font-weight: 800 !important; margin: 0px 0px 8px !important;}',
      '#cconsent-bar .ccb__left p { margin: 0px 0px 8px !important;}',

      '#cconsent-bar .ccb__right {-webkit-box-direction: normal !important; -webkit-box-orient: horizontal !important; display: flex !important; flex-direction: column-reverse !important; margin: 16px 8px 8px !important;}',
        '@media (min-width: 744px) { #cconsent-bar .ccb__right {flex-direction: row !important; margin-top: 8px !important; flex-direction: row-reverse !important;} }',
        '@media (min-width: 1128px) { #cconsent-bar .ccb__right {flex-direction: row !important;} }',

        '.ccb__button-accept {margin: 6px 0px !important; min-width: 160px !important;}',
        '@media (min-width: 744px) { .ccb__button-accept { margin: 0px 6px 0px 0px !important; white-space: nowrap !important;} }',
        '@media (min-width: 1128px) { .ccb__button-accept { margin-left: 6px !important; } }',

      '.ccb__button-preferences {margin: 6px 0px !important;}',
         '@media (min-width: 744px) { .ccb__button-preferences { margin: 0px !important; white-space: nowrap !important; } }',

      '.accept-btn {background: ' + window.CookieConsent.config.theme.barMainButtonColor + ' !important; color: ' + window.CookieConsent.config.theme.barMainButtonTextColor + ' !important; cursor: pointer !important; display: inline-block !important; margin: 0px !important; position: relative !important; text-align: center !important; text-decoration: none !important; touch-action: manipulation !important; font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important; font-size: 16px !important; line-height: 20px !important; font-weight: 600 !important; border-radius: 8px !important; outline: none !important; padding: 14px 24px !important; transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important; border: none !important; width: 100% !important;}',
        '.accept-btn:hover { background: ' + window.CookieConsent.config.theme.barMainButtonHoverColor + ' !important; color: ' + window.CookieConsent.config.theme.barMainButtonHoverTextColor + ' !important; border: none !important; }',
        '.accept-btn:active { background: ' + window.CookieConsent.config.theme.barMainButtonHoverColor + ' !important; color: ' + window.CookieConsent.config.theme.barMainButtonHoverTextColor + ' !important; transform: scale(0.96) !important; border: none !important; }',

        '.preferences-btn {cursor: pointer !important; display: inline-block !important; margin: 0px !important; position: relative !important; text-align: center !important; text-decoration: none !important; touch-action: manipulation !important; font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important; font-size: 16px !important; line-height: 20px !important; font-weight: 600 !important; border-radius: 8px !important; border-width: 1px !important; border-style: solid !important; outline: none !important; padding: 13px 23px !important; transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important; border-color: rgb(34, 34, 34) !important; background: rgb(255, 255, 255) !important; color: rgb(34, 34, 34) !important; width: 100% !important;}',
        '.preferences-btn:hover { border-color: rgb(0, 0, 0) !important; background: rgb(247, 247, 247) !important; color: rgb(34, 34, 34) !important; }',
        '.preferences-btn:active { transform: scale(0.96) !important; border-color: rgb(0, 0, 0) !important; background: rgb(247, 247, 247) !important; color: rgb(34, 34, 34) !important; }',

        '#cconsent-bar.ccb--hidden {transform: translateY(100%) !important; display:block !important;}',

      '#cconsent-modal-bar { opacity: 1 !important; display:flex !important; font-size:14px !important; line-height: 18px !important; color:#666 !important; width: 100vw !important; height: 100vh !important; position:fixed !important; left:0 !important; top:0 !important; right:0 !important; bottom: 0 !important; font-size:14px !important; background-color:rgba(0,0,0,0.34) !important; z-index:999999 !important; }',
      '#cconsent-modal-bar.ccb--close { opacity: 0 !important; display: none !important; }',

      '#cconsent-modal {font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif; font-size: 14px; color: #484848; line-height: 1.43; display: none; flex-direction: row !important; align-items:center; justify-content:center; font-size:16px important; line-height:20px!important; color:#666; width:100vw; height:100vh; position:fixed; left:0; top:0; right:0; bottom:0; background-color:rgba(0,0,0,0.34); z-index:9999; padding-top: 12px !important;}',
      '#cconsent-modal.ccm--visible { display: flex }',
      '#cconsent-modal h2, #cconsent-modal h3 { margin-top: 0 !important; margin-bottom: 10px !important; color:#333 !important; font-size: 20px !important; }',
      '#cconsent-modal .ccm__content {-webkit-box-direction: normal !important; -webkit-box-orient: vertical !important; display: flex !important; flex-direction: column !important; box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px !important; width: 100% !important; max-width: 568px !important; max-height: 100% !important; background-color: #fff; border-radius: 12px; }',
      '#cconsent-modal .ccm__content > .ccm__content__heading { display: flex !important; flex: 0 0 auto !important; align-items: center !important; justify-content: space-between; !important; border-bottom: 1px solid rgb(235, 235, 235) !important; padding: 0 24px !important; min-height: 64px !important; position:relative; }',
      '#cconsent-modal .ccm__content > .ccm__content__heading .header {-webkit-box-pack: justify !important; -webkit-box-align: center !important; display: flex !important; align-items: center !important; justify-content: space-between !important; width: 100%;}',
      '#cconsent-modal .ccm__content > .ccm__content__heading .header .header--left { -webkit-box-flex: 0 !important; flex: 0 0 16px !important; text-align: left !important; }',
      '#cconsent-modal .ccm__content > .ccm__content__heading .header .header--right { -webkit-box-flex: 0 !important; flex: 0 0 16px !important; text-align: right !important; }',
      '#cconsent-modal .ccm__content > .ccm__content__heading .header .header--center h2 { font-size: 16px !important; font-weight:600!important; color:#333!important; margin: 0!important; padding: 0!important; }',
      '#cconsent-modal .ccm__content > .ccm__content__heading .ccm__cheading__close { font-weight:600!important; color:#888!important; cursor:pointer!important; font-size:26px!important; position: absolute!important; right:15px!important; top: 15px!important; }',
      '#cconsent-modal .ccm__content > .ccm__content__body { flex: 1 1 auto !important; overflow-y:auto!important; padding: 0 !important; margin: 0!important; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__content__wrapper {  padding: 24px 24px 48px 24px !important; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__category .ccm__list ul { margin: 0!important; padding-left: 0!important; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__category { padding-top: 35px!important; }',

      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__category .ccm__list ul li { -webkit-box-pack: justify !important; -webkit-box-align: center !important; display: flex !important; align-items: center !important; justify-content: space-between !important; height: 64px !important; border-bottom: 1px solid rgb(235, 235, 235) !important }',
      '@media (max-width: 743px) { #cconsent-modal { height: 100%; padding: 0!important; } #cconsent-modal .ccm__content { max-width:100vw!important; height:100%!important; max-height:initial!important; border-radius: 0!important; } }',
      '@media (min-width: 744px) and (min-height: 475px) { #cconsent-modal { padding: 40px!important; } }',

      '#cconsent-modal .ccm__toggle {-webkit-box-pack: center; -webkit-box-align: center; position: absolute; top: -1px; left: -1px; display: flex; align-items: center; justify-content: center; width: 32px;height: 32px; background-color: rgb(255, 255, 255); color: rgb(255, 255, 255); border-radius: 50%; border-style: solid; border-width: 2px; border-color: rgb(176, 176, 176); transition: -ms-transform 250ms ease-in-out 0s, -webkit-transform 250ms ease-in-out 0s, transform 250ms ease-in-out 0s; transform: translate3d(0px, 0px, 0px);}',
      '#cconsent-modal .ccm__switch--on { border-radius: 32px !important; border-style: solid !important; border-width: 1px !important; cursor: pointer !important; height: 32px !important; position: relative !important; min-width: 48px !important; width: 48px !important; outline: none !important; background-color: rgb(34, 34, 34) !important; border-color: rgb(34, 34, 34) !importan }',
      '#cconsent-modal .ccm__switch--on .ccm__toggle { background-color: rgb(255, 255, 255)!important; border-color: rgb(34, 34, 34)!important; transform: translate3d(16px, 0px, 0px)!important; }',
      '#cconsent-modal .ccm__switch--on:disabled { cursor: not-allowed !important; background-color: rgb(221, 221, 221) !important; border-color: rgb(221, 221, 221) !important; }',
      '#cconsent-modal .ccm__switch--on:disabled > .ccm__toggle { color: lightgrey !important; border-color: rgb(221, 221, 221) !important; }',
      '#cconsent-modal .ccm__switch--off { -webkit-box-pack: center !important; -webkit-box-align: center !important; border-radius: 32px !important; border-style: solid !important; border-width: 1px !important; cursor: pointer !important; height: 32px !important; position: relative !important; min-width: 48px !important; width: 48px !important; outline: none !important; background-color: rgb(176, 176, 176) !important; border-color: rgb(176, 176, 176) !important; }',
      '#cconsent-modal .ccm__content__footer {-webkit-box-pack: justify !important; -webkit-box-align: center !important; flex: 0 0 auto !important; padding: 16px 24px; text-align:center; display: flex !important; align-items: center !important; justify-content: space-between !important; border-top: 1px solid rgb(235, 235, 235) !important; }',

      '#cconsent-modal .ccm__content__footer button {background: ' + window.CookieConsent.config.theme.modalMainButtonColor + ' !important; color: ' + window.CookieConsent.config.theme.modalMainButtonTextColor + ' !important; cursor: pointer !important; display: inline-block !important; margin: 0px !important; position: relative !important; text-align: center !important; text-decoration: none !important; touch-action: manipulation !important; font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important; font-size: 16px !important; line-height: 20px !important; font-weight: 600 !important; border-radius: 8px !important; outline: none !important; padding: 14px 24px !important; transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important; border: none !important; width: 100% !important;}',
        '#cconsent-modal .ccm__content__footer button:hover { background: ' + window.CookieConsent.config.theme.modalMainButtonHoverColor + ' !important; color: ' + window.CookieConsent.config.theme.modalMainButtonHoverTextColor + ' !important; border: none !important; }',
        '#cconsent-modal .ccm__content__footer button:active { background: ' + window.CookieConsent.config.theme.modalMainButtonHoverColor + ' !important; color: ' + window.CookieConsent.config.theme.modalMainButtonHoverTextColor + ' !important; transform: scale(0.96) !important; border: none !important; }',

        '#cconsent-modal .ccm__content__footer button.consent-close { margin-right: 20px; }',

      'button.cmm__close { appearance: none !important; display: inline-block !important; border-radius: 50% !important; border: none !important; outline: none !important; margin: 0px !important; padding: 0px !important; color: rgb(34, 34, 34) !important; cursor: pointer !important; touch-action: manipulation !important; position: relative !important; background: transparent !important; transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important; }',
      'button.cmm__close::before { content: "" !important; display: block !important; position: absolute !important; top: 50% !important; left: 50% !important; transform: translate(-50%, -50%) !important; width: 32px !important; height: 32px !important; border-radius: 50% !important; }',
      'button.cmm__close:hover::before { background: rgb(247, 247, 247) !important; }',

      '#cconsent-bar a, #cconsent-modal a {font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important; font-size: 14px !important; color: #484848 !important; line-height: 1.43 !important; text-decoration: underline !important; }',
      '#cconsent-bar a:hover, #cconsent-modal a:hover { text-decoration: underline !important; color: ' + window.CookieConsent.config.theme.modalMainButtonHoverColor + ' !important; }',

      '.icon-cross { stroke: white!important; fill: transparent!important; stroke-linecap: round; stroke-width: 3!important; }',
      '._e296pg {position: relative !important;}',
      '@media print { #cconsent-modal, #cconsent-bar, #cconsent-icon { display: none !important; } }'
    );
  }

  buildIcon() {
    return el('div#cconsent-icon',
      el('div.ccb__wrapper',
        el('a.ccb__edit')
      )
    );
  }

  buildBar() {
    return el('div#cconsent-bar.ccb--hidden',
      el('div.ccb__wrapper',
        el('div.ccb__left',
            el('h1', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainTitle')),
            el('p', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barMainText')),
          ),
        el('div.ccb__right',
          el('div.ccb__button-preferences',
              el('button.preferences-btn.ccb__edit', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barLinkSetting')),
          ),
          el('div.ccb__button-accept',
            el('button.accept-btn.consent-give', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barBtnAcceptAll')),
          ),

        )
      )
    );
  }

  buildModal() {
    // Cookie names list middleware
    let listCookies = function(category) {
      let list = [];
      for (let service in window.CookieConsent.config.services) {
        (window.CookieConsent.config.services[service].category === category) && list.push({key: service, service: window.CookieConsent.config.services[service]});
      }
      if (list.length) {
        let listItems = [];
        for (let item in list) {
          let { key, service } = list[item];
          let { needed, wanted, checked } = service;

          checked = !!((typeof checked === 'undefined' || checked) || needed || wanted);

          listItems.push(
            el('li',
              el('span', Language.getTranslation(service, window.CookieConsent.config.language.current, 'name')),
              el('button.ccm_switch',  { id: key, 'disabled': !!(needed), 'class': checked ? 'ccm__switch--on ' : 'ccm__switch--off', 'aria-checked': checked, role: 'switch', type: 'button' },
                  el('div.ccm__toggle')
                )
            ));
        }
        return [
          el('div.ccm__list', el('ul.measure-cookies-section', listItems))
        ];
      }
    };

    let modalTabHeader = function () {
      return el('div.header',
          el('div.header--left',
            el('button.cmm__close.consent-close',
              el('span._e296pg',
                el(document.createElementNS('http://www.w3.org/2000/svg', 'svg'), {
                  'aria-label': 'Voltar',
                  version: '1.2',
                  preserveAspectRatio: 'none',
                  viewBox: '0 0 32 32',
                  style: 'display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 4; overflow: visible;'},
                el(document.createElementNS('http://www.w3.org/2000/svg', 'path'), {
                  'xmlns:default': 'http://www.w3.org/2000/svg',
                  d: 'm20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932',
                  style: 'fill: rgb(255, 255, 255);' })
                ))
              )
          ),
          el('div.header--center', el('h2', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainTitle'))),
          el('div.header--right', ''),
      );
    };

    let modalNavigation = function () {
      return el('div.tabs',
          el('ul.lg',
              el('li', el('a.btn.btn-default', { href: 'javascript:;', rel: 'noopener noreferrer' }, 'Definições')),
              el('li', el('a.btn.btn-default', { href: 'javascript:;', rel: 'noopener noreferrer'}, 'Politíca de cookies')),
              el('li', el('a.btn.btn-default', { href: 'javascript:;', rel: 'noopener noreferrer'}, 'Política de privacidade'))
          )
      );
    };

    let modalCategories = function () {
      let contentItems = [];
      let i = 0;
      for (let key in window.CookieConsent.config.categories) {
        let servicesList = listCookies(key);
        if (servicesList) {
          contentItems.push(
              el('section.ccm__category', { 'data-category': key } ,
                el('h2.ccm__title', Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'name')),
                el('div.ccm__description', Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'description')),
                el('div.ccm__services', servicesList)
              )
          );
        }
        i++;
      }
      return contentItems;
    };

    return el('div#cconsent-modal', { role: 'dialog', 'aria-labelledby': 'prefConsent' },
      el('div.ccm__content',
        el('div.ccm__content__heading', modalTabHeader()
      ),
      el('div.ccm__content__body',
        el('div.ccm__content__wrapper',
          el('div', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainText')),
          el('div.ccm__tabs', modalCategories())
        )
      ),
      el('div.ccm__content__footer',
        el('button#ccm__footer__consent-modal-submit', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalBtnAcceptAll')))
      )
    );

  }

  render(name, elem, callback) {
    if (typeof callback === 'undefined') callback = function(){};
    if (typeof this.elements[name] !== 'undefined') {
      this.elements[name].parentNode.replaceChild(elem, this.elements[name]);
      this.elements[name] = elem;
      callback(elem);
      return elem;
    } else {
      let insertedElem = mount(document.body, elem);
      if (insertedElem) {
        this.elements[name] = insertedElem;
      }
      callback(insertedElem);
      return insertedElem;
    }
  }

  buildInterface(callback) {
    if (typeof callback === 'undefined') callback = () => {};
    let that = this;
    Utilities.ready(function() {
      that.render('style', that.buildStyle());
      if (window.CookieConsent.config.hasIcon) {
        that.render('icon', that.buildIcon());
      }
      that.render('bar', that.buildBar(), (bar) => {
        // Show the bar after a while
        if (!window.CookieConsent.config.cookieExists) {
          setTimeout(() => {
            bar.classList.remove('ccb--hidden');
          }, window.CookieConsent.config.barTimeout);
        }
      });
      that.render('modal', that.buildModal());
      callback();
    });
  }

  addEventListeners(elements) {

    let buttonConsentClose = document.querySelectorAll('.consent-close');
    for (let button of buttonConsentClose) {
      button.addEventListener('click', (event) => {

        this.elements['modal'].classList.remove('ccm--visible');

        if (!window.CookieConsent.config.saveOnClose && !window.CookieConsent.config.cookieExists) {
          this.elements['bar'].classList.remove('ccb--hidden');
        } else {
          this.buildCookie((cookie) => {
            this.setCookie(cookie);
          });
        }
      });
    }

    // If you click Accept all cookies on bar
    let buttonConsentGive = document.querySelectorAll('.consent-give');
    for (let button of buttonConsentGive) {
      button.addEventListener('click', () => {
        // We set config to full consent
        for (let key in window.CookieConsent.config.categories) {
          window.CookieConsent.config.categories[key].wanted =
            window.CookieConsent.config.categories[key].checked = true;
        }
        this.writeBufferToDOM();
        this.buildCookie((cookie) => {
          this.setCookie(cookie);
        });
        this.elements['bar'].classList.add('ccb--hidden');
        this.elements['modal'].classList.remove('ccm--visible');
      });
    }

    // If you click Cookie settings and open modal
    Array.prototype.forEach.call(document.getElementsByClassName('ccb__edit'), (edit) => {
      edit.addEventListener('click', () => {
        this.elements['bar'].classList.add('ccb--hidden');
        this.elements['modal'].classList.add('ccm--visible');
        this.elements['modal'].focus();
      });
    });

    // new switcher
    Utilities.qsa('.measure-cookies-section li button:not(:disabled)', (button) => {
      button.addEventListener('click', (event) => {
        let el = event.target;
        let parent = el.parentNode;
        if (el.nodeName === 'BUTTON') {
          parent = el;
          el = parent.firstChild;
        }
        if (parent.classList.contains('ccm__switch--off')) {
          parent.setAttribute('class', 'ccm__switch ccm__switch--on');
          parent.setAttribute('aria-checked', 'true');
        } else {
          parent.setAttribute('class', 'ccm__switch ccm__switch--off');
          parent.setAttribute('aria-checked', 'false');
        }
      });
    });

    // If you click submit on cookie settings
    let btnConfig = document.getElementById('ccm__footer__consent-modal-submit');
    if (btnConfig) {
      btnConfig.addEventListener('click', () => {
        let switchElements = this.elements['modal'].querySelectorAll('.measure-cookies-section li button');
        Array.prototype.forEach.call(switchElements, (switchElement) => {
          let isChecked = (switchElement.getAttribute('aria-checked') === 'true');
          window.CookieConsent.config.services[switchElement.id].wanted =
            window.CookieConsent.config.services[switchElement.id].checked = isChecked;
        });
        this.buildCookie((cookie) => {
          this.setCookie(cookie, () => {
            this.elements['modal'].classList.remove('ccm--visible');
            this.elements['bar'].classList.add('ccb--hidden');
          });
        });
        this.writeBufferToDOM();
      });
    }
  }

  writeBufferToDOM() {
    for (let action of window.CookieConsent.buffer.appendChild) {
      if (window.CookieConsent.config.services[action.service].wanted === true) {
        Node.prototype.appendChild.apply(action.this, action.arguments);
      }
    }
    for (let action of window.CookieConsent.buffer.insertBefore) {
      if (window.CookieConsent.config.services[action.service].wanted === true) {
        action.arguments[1] = (action.arguments[0].parentNode === null) ? action.this.lastChild : action.arguments[1];
        Node.prototype.insertBefore.apply(action.this, action.arguments);
      }
    }
  }

  buildCookie(callback) {
    let cookie = {
      version: window.CookieConsent.config.cookieVersion,
      services: Utilities.getGlobalServices(),
    };
    if (callback) callback(cookie);
    return cookie;
  }

  setCookie(cookie, callback) {
    Services.saveUserConsent(cookie);
    const expires_in = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `__cconsent=${JSON.stringify(cookie)}; expires=${expires_in}; path=/;`;
    if (!window.CookieConsent.config.cookieExists) {
      window.CookieConsent.config.cookieExists = true;
    }
    if (callback) callback();
  }

}
