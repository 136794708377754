import request from 'request';

export default class Services {

    constructor() {
        this.saveUserConsent();
    }

    static saveUserConsent(data, callback) {
        if (!window.CookieConsent.config.apiUri) {
            return;
        }
        request.post(window.CookieConsent.config.apiUri, { data }, (error, response, body) => {
            if (callback) callback();
        });
    }

}
