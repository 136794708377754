import Utilities from "./Utilities";
import Filter from './Filter';

export default class ScriptTagFilter extends Filter {

  constructor() {
    super();
  }

  init() {
    this.filterTags();
  }

  filterTags() {
    Utilities.ready(() => {
      let blacklist = super.createBlacklist('script-tag');
      let scriptTags = document.querySelectorAll('script[type="text/plain"]');
      for (let scriptTag of scriptTags) {
        if (blacklist.indexOf(scriptTag.dataset.consent) < 0) {
          let newtag = document.createElement('script');
          let parentNode = scriptTag.parentNode;
          scriptTag.type = 'text/javascript';
          for(let attribute of scriptTag.attributes) {
            newtag.setAttribute(attribute.nodeName, attribute.nodeValue);
          }
          newtag.innerHTML = scriptTag.innerHTML;
          parentNode.insertBefore(newtag,scriptTag);
          parentNode.removeChild(scriptTag);
        }
      }
    });
  }
}
