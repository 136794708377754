import Filter from './Filter';

export default class InsertScriptFilter extends Filter {

  constructor() {
    super();
  }

  init() {
    this.overrideAppendChild();
    this.overrideInsertBefore();
  }

  overrideAppendChild() {
    Element.prototype.appendChild = function(elem) {
      if (arguments[0].tagName === 'SCRIPT') {
        // console.log('Appending:', arguments[0].outerHTML);
        for (let key in window.CookieConsent.config.services) {
          // Did user opt-in?
          if (window.CookieConsent.config.services[key].type === 'dynamic-script') {
            if (arguments[0].outerHTML.indexOf(window.CookieConsent.config.services[key].search) >= 0) {
              if (window.CookieConsent.config.services[key].wanted === false) {
                window.CookieConsent.buffer.appendChild.push({'this': this, service: key, arguments: arguments});
                return undefined;
              }
            }
          }
        }
      }
      return Node.prototype.appendChild.apply(this, arguments);
    }
  }

  overrideInsertBefore() {
    Element.prototype.insertBefore = function(elem) {
      if (arguments[0].tagName === 'SCRIPT') {
        // console.log('Inserting:', arguments[0].outerHTML);
        for (let key in window.CookieConsent.config.services) {
          // Did user opt-in?
          if (window.CookieConsent.config.services[key].type === 'dynamic-script') {
            if (arguments[0].outerHTML.indexOf(window.CookieConsent.config.services[key].search) >= 0) {
              if (window.CookieConsent.config.services[key].wanted === false) {
                console.log({'this': this, service: key, arguments: arguments});
                window.CookieConsent.buffer.insertBefore.push({'this': this, service: key, arguments: arguments});
                return undefined;
              }
            }
          }
        }
      }
      return Node.prototype.insertBefore.apply(this, arguments);
    }
  }

}
